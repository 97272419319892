import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  // margin: -2em 0 0 0;
  // padding: 0 1.5em 2em;
  margin: 5em 0 0em 0;
  // max-width: ${props => props.theme.sizes.maxWidthCentered};
  span {
    margin: 0 0.5rem;
  }
`

const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  // width: 100%;
  // padding: 1em 1em;
  // max-width: ${props => props.theme.sizes.maxWidthCentered};
  a {
    // background: ${props => props.theme.colors.primary};
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.6);
    color: black;
    padding: 0.2em 1.6em;
    // font-size: 90%;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      background: white;
    }
    // border-bottom: 2px solid;
    // background: rgba(255, 255, 255, 0.8);
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`

const PreviousLink = styled(Link)`
  margin-right: 2em;
  order: 1;
  font-family: ${props => props.theme.fonts.navigation};
`

const NextLink = styled(Link)`
  // margin-left: auto;
  order: 2;
  font-family: ${props => props.theme.fonts.navigation};
`

const PostLinks = props => {
  return (
    <Wrapper>
      <Box>
        {props.previous && (
          <PreviousLink to={`${props.basePath}/${props.previous.slug}/`}>
            Prev
          </PreviousLink>
        )}
        {props.next && (
          <NextLink to={`${props.basePath}/${props.next.slug}/`}>
            Next
          </NextLink>
        )}
      </Box>
    </Wrapper>
  )
}

export default PostLinks
