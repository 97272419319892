import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
@media screen and (max-width: ${props => props.theme.responsive.medium}) {
  flex-flow: column;
}
display: flex;
flex-flow: row;
flex-wrap: wrap;
`

const PostTwoColumn = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default PostTwoColumn
